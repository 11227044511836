import React, { useState } from "react"
import { Button, Card, Checkbox, message, Tag } from "antd"
import Viewer from 'react-viewer';
import { AimOutlined } from "@ant-design/icons";
import TextArea from "antd/lib/input/TextArea";
import { usePutData } from "../hooks/serviceGetways";
import _ from "lodash";


let PoleDetails = ({ pole }) => {
    const [poleDetail, setPoleDetail] = useState(pole)
    const putData = usePutData()
    let [showImages, setShowImages] = useState({ images: [], index: 0 });

    const save = () => {
        putData(`poles`, {
            ..._.omit(poleDetail, ['jobName', 'convertBarcode', 'jobName', 'joinedBarcode','sebekeUnsur','createdAt','createdBy'])
        }).then(({ data }) => {
            message.success("Direk başarılı bir şekilde güncellendi.")
        }).catch(() => message.error("Direk güncellenirken hata oluştu."))
    }
    return <Card style={{ border: 'none', width: '100%', height: '100%' }}>
        <Viewer
            downloadable={true}
            downloadInNewWindow={true}
            zoomSpeed={0.5}

            visible={!!showImages?.images?.length}
            onClose={() => { setShowImages({ images: [], index: 0 }); }}
            activeIndex={showImages?.index}
            images={showImages.images.map((img) => ({ src: img.url, alt: img._id, downloadUrl: img.url }))}
        />
        <div className="d-flex align-items-center justify-content-end"><Button onClick={save}>Kaydet</Button></div>
        <div className="w-100 d-flex">
            <div style={{ width: '35%' }} className="d-flex justify-content-start align-items-start flex-column">
                <div className="d-block mb-2">
                    <span className="fs-5 font-weight-500">HEX.Barcode : {poleDetail.barcode}</span>
                </div>
                <div className="d-block mb-2">
                    <span className="fs-5 font-weight-500">Barcode : {poleDetail.convertBarcode}</span>
                </div>

                <div className="d-block mb-2">
                    <span className="fs-5 font-weight-500">İş Adı : {poleDetail.jobName}</span>
                </div>

                <div className="d-block mb-2">
                    <span className="d-flex align-items-center justify-content-center fs-5 gap-2 font-weight-500">Konum Bilgisi:  {poleDetail.lat && poleDetail.long ?
                        <Tag color="green"><AimOutlined className="me-1" />Konum Var</Tag> :
                        <Tag style={{ width: "fit-content" }} className="d-flex align-items-center justify-content-start" color="volcano"><AimOutlined /><span>Konum Yok</span></Tag>}</span>
                </div>

                <div className="d-block mb-2">
                    <span className="d-flex align-items-center fs-5 font-weight-500">Direk Türü : {poleDetail.poleType.toUpperCase()}</span>
                </div>

                <div className="d-block mb-2 fs-5 font-weight-500">
                    <span>Direk Tipi : {poleDetail.poleGenre.toUpperCase()}</span>
                </div>
            </div>
            <div style={{ width: '65%' }} className="d-block my-2">
                <div className="fs-5 font-weight-500">Durumu :</div>
                <ul className="m-0 my-2 p-0 d-flex flex-wrap align-items-start justify-content-start">
                    {poleDetail.maintenances.map(item => (
                        <li className="w-50" style={{ fontSize: 18, listStyle: 'none' }} key={item.key}>
                            {item.key === 'other' ?
                                <div>
                                    <Checkbox checked={item.value} onChange={e => setPoleDetail(p => ({
                                        ...p, maintenances: p.maintenances.map(x => {
                                            if (x.key === item.key) {
                                                x.value = e.target.checked
                                            }
                                            return x
                                        })
                                    }))}>Diğer</Checkbox>
                                    <TextArea value={item.name} onChange={e => setPoleDetail(p => ({
                                        ...p, maintenances: p.maintenances.map(x => {
                                            if (x.key === item.key) {
                                                x.name = e.target.value
                                            }
                                            return x
                                        })
                                    }))}></TextArea>
                                </div>
                                : <div className="d-flex align-items-center justify-content-start gap-1">
                                    <Checkbox checked={item.value} onChange={e => setPoleDetail(p => ({
                                        ...p, maintenances: p.maintenances.map(x => {
                                            if (x.key === item.key) {
                                                x.value = e.target.checked
                                            }
                                            return x
                                        })
                                    }))}>{item.name}</Checkbox>
                                    {item.countable && <div className="d-flex align-items-center justify-content-center gap-1">
                                        <Button size="small" onClick={() => setPoleDetail(p => ({
                                            ...p,
                                            maintenances: p.maintenances.map(x => ({ ...x, count: x.key === item.key ? x.count + 1 : x.count }))

                                        }))}>+</Button>
                                        <span className="fs-6">{item.count}</span>
                                        <Button size="small"
                                            onClick={() => setPoleDetail(p => ({
                                                ...p,
                                                maintenances: p.maintenances.map(x => ({ ...x, count: x.key === item.key && x.count > 0 ? x.count - 1 : x.count }))
                                            }))}
                                        >-</Button>
                                    </div>}
                                </div>}
                        </li>
                    ))}
                </ul>
            </div>
        </div>

        <div className="mt-2 d-flex justify-content-start align-items-start flex-wrap gap-2">
            {poleDetail.images.map((item, i) => <img className="pole-image" key={i} src={item?.thumb} width={150} onClick={() => setShowImages({ images: poleDetail.images, index: i })} alt="resimler" />)}
        </div>
    </Card >

}

export default PoleDetails