import React, { useContext, useEffect, useState } from 'react'
import {
    Card,
    CardHeader,
    CardTitle,
    CardBody,
    Row,
    Col,
    Label
} from "reactstrap"
import { Input, Form, Button, message, Select } from 'antd'
import { AiOutlinePlus } from 'react-icons/ai'
import { useHistory, useParams } from "react-router-dom";
import { useGetData, usePostData, usePutData, useUploadFile } from '../../hooks/serviceGetways';
import { ContextLayout } from '../../utility/LayoutContext';

const tailLayout = {
    labelCol: { span: 0 },
    wrapperCol: { offset: 0, span: 24 },
};

let JobView = () => {
    let history = useHistory()
    let { id } = useParams()
    const { user } = useContext(ContextLayout)
    let postData = usePostData()
    let getData = useGetData()
    let [upload, progress] = useUploadFile()
    let putData = usePutData()
    let [job, setJob] = useState({ active: id === "new" ? true : false, phone: "" })
    let [users, setUsers] = useState([])

    useEffect(() => {
        if (id && id !== "new")
            getData("jobs/find/" + id, {}).then(({ result, resultMessage }) => {
                if (result)
                    setJob(result)
                else
                    history.push("/jobs")
            })
        getData("users", {}).then(({ result, resultMessage }) => {
            if (result)
                setUsers(result)
            else
                history.push("/jobs")
        })
    }, [id])

    let save = async () => {

        if (id === "new" && job.password !== job.passwordCheck) {
            message.error("Şifreler uyumsuz")
            return false
        }

        if (id && id !== "new")
            putData("jobs", { ...job }).then(({ result, resultMessage }) => {
                if (result) {
                    message.success("İş Başarılı bir şekilde güncellendi.", 5)
                    history.push("/jobs")
                }
            }).catch(err =>
                message.error(err.message)
            )
        else
            postData("jobs", { ...job }).then(({ result, resultMessage }) => {
                if (result) {
                    message.success("İş Başarılı bir şekilde eklendi.", 5)
                    history.push("/jobs")
                }
            }).catch(err =>
                message.error(err.message)
            )
    }

    return (
        <Row>
            <Col sm="12" className="p-0">
                <Card style={{ boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px" }}>
                    <CardHeader>
                        <CardTitle>İş {id === "new" ? "Ekle" : "Düzenle"}</CardTitle>

                    </CardHeader>
                    <CardBody>
                        <Form layout="horizontal" labelAlign="left" size="middle">

                            <Row className="mx-0 mb-2" col="12">
                                <Col className="pl-0" sm="6" style={{ fontSize: 14, display: "flex" }} md={{ size: 6, offset: 0 }} >
                                    <Label className="me-3"> Durumu :</Label>
                                    <Select style={{ width: 300 }} value={job.status} onChange={(v) => setJob({ ...job, status: v })}>
                                        <Select.Option key="pending" value="pending">Yapılıyor</Select.Option>
                                        <Select.Option key="completed" value="completed">Tamamlandı</Select.Option>
                                        <Select.Option key="inactive" value="inactive">Pasif</Select.Option>
                                        <Select.Option key="second-pending" value="second-pending">2.Seviye Bakım Yapılıyor</Select.Option>
                                        <Select.Option key="second-completed" value="second-completed">2.Seviye Bakım Tamamlandı</Select.Option>
                                    </Select>
                                </Col>

                                <Col className="pl-0" sm="6" style={{ fontSize: 14, display: "flex" }} md={{ size: 6, offset: 0 }} >
                                    <Label className="me-3"> İşin Sahibi :</Label>

                                    <Select style={{ minWidth: 250 }} value={job.personId} onChange={(v) => setJob({ ...job, personId: v })}>
                                        {users.map((user) => <Select.Option key={user.personId} value={user.personId}>{user.personId}</Select.Option>)}
                                    </Select>

                                </Col>
                            </Row>

                            <Row className="mx-0" col="12">
                                <Col className="pl-0" sm="12" md={{ size: 6, offset: 0 }}  >
                                    <Form.Item {...tailLayout}>
                                        <Label for="phone">Adı</Label>
                                        <Input style={{ background: '#ededed' }} placeholder="Adı" value={job?.name}></Input>
                                    </Form.Item>
                                </Col>
                                <Col className="pl-0" sm="12" md={{ size: 6, offset: 0 }}  >
                                    <Form.Item  {...tailLayout}>
                                        <Label for="cbsId">CBSID</Label>
                                        <Input style={{ background: '#ededed' }} placeholder="CBS ID" value={job?.cbsId}></Input>
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Row className="mx-0" col="12">
                                <Col className="pl-0" sm="12" md={{ size: 6, offset: 0 }}  >
                                    <Form.Item {...tailLayout}>
                                        <Label for="city">İl</Label>
                                        <Input style={{ background: '#ededed' }} type="input" placeholder="Şehir" value={job?.city}></Input>
                                    </Form.Item>
                                </Col>
                                <Col className="pl-0" sm="12" md={{ size: 6, offset: 0 }}  >

                                    <Form.Item {...tailLayout}>
                                        <Label for="district">İlçe</Label>
                                        <Input style={{ background: '#ededed' }} type="input" placeholder="İlçe" value={job?.district}></Input>
                                    </Form.Item>

                                </Col>
                            </Row>

                            <Row className="mx-0" col="12">
                                <Col className="pl-0" sm="12" md={{ size: 6, offset: 0 }}  >
                                    <Form.Item {...tailLayout}>
                                        <Label for="country">Bölge</Label>
                                        <Input style={{ background: '#ededed' }} type="input" placeholder="Bölge" value={job?.country}></Input>
                                    </Form.Item>
                                </Col>
                                <Col className="pl-0" sm="12" md={{ size: 6, offset: 0 }}  >
                                    <Form.Item {...tailLayout}>
                                        <Label for="outskirts">Mahalle</Label>
                                        <Input style={{ background: '#ededed' }} type="outskirts" placeholder="Mahalle" value={job?.outskirts}></Input>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row className="mx-0" col="12">
                                <Col className="pl-0" sm="12" md={{ size: 6, offset: 0 }}  >
                                    <Form.Item {...tailLayout}>
                                        <Label for="sebekeUnsur">Şebeke Unsur</Label>
                                        <Input style={{ background: '#ededed' }} type="input" placeholder="Şebeke Unsur" value={job?.sebekeUnsur}></Input>
                                    </Form.Item>
                                </Col>
                                <Col className="pl-0" sm="12" md={{ size: 6, offset: 0 }}  >
                                    <Form.Item {...tailLayout}>
                                        <Label for="type">Tipi</Label>
                                        <Input style={{ background: '#ededed' }} type="type" placeholder="Tipi" value={job?.type}></Input>
                                    </Form.Item>
                                </Col>
                            </Row>

                            {(user?.role === "admin" || user?.role === "outside") && <Row className="mr-1">
                                <Col xs="12" sm="12" md={{ size: 3, offset: 9 }} >
                                    <Button type="primary" style={{ width: "100%", justifyContent: "center" }} icon={<AiOutlinePlus />} onClick={() => save()}> {id === "new" ? "Kaydet" : "Güncelle"}</Button>
                                </Col>
                            </Row>}
                        </Form>
                    </CardBody>
                </Card>
            </Col>
        </Row >
    )
}

export default JobView;