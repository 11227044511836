
let config = [
    {
        id: 1,
        path: "",
        title: "Anasayfa",
        icon: "bx bxs-grid-alt",
        role: ["user","admin","outside"]
    },
    {
        id: 2,
        path: "poles",
        title: "Direkler",
        icon: "bx bx-cube",
        role: ["user","admin","outside"]
    },
    {
        id: 3,
        path: "users",
        title: "Kullanıcılar",
        icon: "bx bx-user",
        role: ["admin"]
    }
    //,
    // {
    //     id: 4,
    //     path: "reports",
    //     title: "Raporlar",
    //     icon: "bx bxs-report",
    //     role: ["admin"]
    // }
]

export default config
