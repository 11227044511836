import React, { useContext, useEffect, useState } from "react"
import { Input, message, Tooltip } from "antd"
import { useGetData } from "../../hooks/serviceGetways"
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { LoadingOutlined } from "@ant-design/icons";
import Viewer from 'react-viewer';
import PoleDetails from "../../components/PoleDetail";
import DownloadReport from "../../components/DownloadReport";
import { ContextLayout } from "../../utility/LayoutContext";


let JobPoleList = () => {
    const { user } = useContext(ContextLayout)
    let [poles, setPoles] = useState([])
    let { id } = useParams()
    let history = useHistory()
    let { search } = useLocation()
    const searchParams = new URLSearchParams(search);
    const barcode = searchParams.get("barcode");
    let [showImages, setShowImages] = useState([]);
    let [loading, setLoading] = useState(true)
    let [searchTerm, setSearchTerm] = useState("")
    let getData = useGetData()

    useEffect(() => {
        if (!id) return;
        getData("poles", { query: { jobId: id } }).then(({ result, resultMessage }) => {
            setPoles(result.rows)
        }).catch(err => {
            message.error(err.message)
        }).finally(() => { setLoading(false) })
    }, [id])

    console.log({ poles: poles.flatMap(x => ({ bar: x.barcode, maintenances: x.maintenances.filter(y => y.key == 'other') })) })
    if (loading)
        return <div className=" d-flex align-items-center justify-content-center vw-90 vh-100 m-auto"><LoadingOutlined style={{ fontSize: 54 }} spin /></div>
    else if (barcode)
        return <PoleDetails pole={poles.find(p => p.joinedBarcode == barcode)} />
    return <>
        <Viewer
            downloadable={true}
            downloadInNewWindow={true}
            zoomSpeed={0.5}

            visible={!!showImages?.length}
            onClose={() => { setShowImages([]); }}
            images={showImages.map((img) => ({ src: img.url, alt: img._id, downloadUrl: img.url }))}
        />
        <div className="d-flex w-100 align-items-center justify-content-between fs-6 font-weight-500">
            <span className="fs-5">{poles?.[0]?.jobName}</span>
            <span>Toplam:{poles.length} Direk</span></div>
        <div className="d-flex w-100 align-items-center justify-content-start fs-6 font-weight-500">
            <Input.Search size="large" className="my-2" value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} />
            <DownloadReport zipStatus={poles[0]?.zipStatus} sebekeUnsur={poles?.[0]?.sebekeUnsur} jobName={poles?.[0]?.jobName} jobId={id} />
        </div>
        <div className="ms-4 d-flex align-items-center justify-content-start flex-wrap gap-lg-4 overflow-auto" style={{ maxHeight: '75vh' }}>
            {poles.filter(pole => pole.joinedBarcode.toString().toLowerCase().indexOf(searchTerm.toLowerCase()) >= 0).map((pole, i) =>
                <div key={i} className="d-flex align-items-center justify-content-center flex-column">
                    <img width={120} className="cursor-pointer" src="/images/folder.png" alt="folder" onDoubleClick={() => {
                        history.push(`/job-poles/${id}?barcode=${pole.joinedBarcode}`)
                        // setShowImages(pole.images)
                    }} />
                    <Tooltip title={pole.joinedBarcode}><div style={{ cursor: 'pointer', maxWidth: 116, whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }}>{pole.joinedBarcode}</div></Tooltip>
                </div>
            )}
        </div>
    </>

}

export default JobPoleList