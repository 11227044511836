import React, { useEffect, useState } from 'react'
import {
    Card,
    CardHeader,
    CardTitle,
    CardBody,
    Row,
    Col,
    Label
} from "reactstrap"
import { Input, Form, Switch, Button, message, Select } from 'antd'
import { AiOutlinePlus } from 'react-icons/ai'
import { useHistory, useParams } from "react-router-dom";
import PhoneInput from '../../components/PhoneInput';
import { useGetData, usePostData, usePutData, useUploadFile } from '../../hooks/serviceGetways';
import SingleFileUpload from '../../components/SingleFileUpload'

const tailLayout = {
    labelCol: { span: 0 },
    wrapperCol: { offset: 0, span: 24 },
};

function View() {
    let history = useHistory()
    let { id } = useParams()

    let postData = usePostData()
    let getData = useGetData()
    let [upload, progress] = useUploadFile()
    let putData = usePutData()
    let [user, setUser] = useState({ active: id === "new" ? true : false, phone: "" })

    useEffect(() => {
        if (id && id !== "new")
            getData("users/find/" + id, {}).then(({ result, resultMessage }) => {
                if (result)
                    setUser(result)
                else
                    history.push("/users")
            })
    }, [id])

    let uploadMedia = async () => {
        let { result, resultMessage } = await upload({ files: [user.avatar] })
        if (result)
            return result[0]
        else
            message.error(resultMessage)
    }


    let save = async () => {

        if (id === "new" && user.password !== user.passwordCheck) {
            message.error("Şifreler uyumsuz")
            return false
        }

        let medias = null

        if (user.avatar && user.avatar.uid) {
            medias = await uploadMedia()
        }

        else if (user.avatar && user.avatar._id)
            medias = user.avatar

        if (id && id !== "new")
            putData("users", { ...user, avatar: medias }).then(({ result, resultMessage }) => {
                if (result) {
                    message.success("Kullanıcı Başarılı bir şekilde güncellendi.", 5)
                    history.push("/users")
                }
            }).catch(err =>
                message.error(err.message)
            )
        else
            postData("users", { ...user, avatar: medias }).then(({ result, resultMessage }) => {
                if (result) {
                    message.success("Kullanıcı Başarılı bir şekilde eklendi.", 5)
                    history.push("/users")
                }
            }).catch(err =>
                message.error(err.message)
            )
    }

    return (
        <Row>
            <Col sm="12">
                <Card style={{ boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px" }}>
                    <CardHeader>
                        <CardTitle>Kullancı {id === "new" ? "Ekle" : "Düzenle"}</CardTitle>

                    </CardHeader>
                    <CardBody>
                        <Form layout="horizontal" labelAlign="left" size="middle">

                            <Row className="mx-0 mb-2" col="12">
                                <Col className="pl-0" sm="6" style={{ fontSize: 14, display: "flex"}} md={{ size: 6, offset: 0 }} >
                                    <Label className="me-3"> Durumu :</Label>
                                    <Switch checkedChildren="Aktif" unCheckedChildren="Pasif" checked={user ? user.active : false} defaultChecked={user ? user.active : false} onChange={(v) => { setUser({ ...user, active: v }) }}></Switch>
                                </Col>
                            </Row>

                            <Row className="mx-0" col="12">
                                <Col className="pl-0" sm="12" md={{ size: 6, offset: 0 }}  >
                                    <Form.Item {...tailLayout}>
                                        <Label for="phone">Adı</Label>
                                        <Input placeholder="Adı" value={user?.name} onChange={(e) => { setUser({ ...user, name: e.target.value }) }}></Input>
                                    </Form.Item>
                                </Col>
                                <Col className="pl-0" sm="12" md={{ size: 6, offset: 0 }}  >
                                    <Form.Item  {...tailLayout}>
                                        <Label for="phone">Soyadı</Label>
                                        <Input placeholder="Soyadı" value={user?.lastname} onChange={(e) => { setUser({ ...user, lastname: e.target.value }) }}></Input>
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Row className="mx-0" col="12">
                                <Col className="pl-0" sm="12" md={{ size: 6, offset: 0 }}  >
                                    <Form.Item {...tailLayout}>
                                        <Label for="phone">Email</Label>
                                        <Input type="email" placeholder="Email" value={user?.email} onChange={(e) => { setUser({ ...user, email: e.target.value }) }}></Input>
                                    </Form.Item>
                                </Col>
                                <Col className="pl-0" sm="12" md={{ size: 6, offset: 0 }}  >

                                    <Form.Item {...tailLayout}>
                                        <Label for="phone">Telefon</Label>
                                        <PhoneInput
                                            value={user.phone}
                                            onChange={e => setUser({ ...user, phone: e.target.value.replace("+90") })}
                                            mask="(999) 999-99-99"
                                            placeholder="(___) ___-__-__"
                                            addonBefore={'+90'}
                                        />
                                    </Form.Item>

                                </Col>
                            </Row>

                            {id === "new" && <Row className="mx-0" col="12">
                                <Col className="pl-0" sm="12" md={{ size: 6, offset: 0 }}  >
                                    <Form.Item {...tailLayout}>
                                        <Label for="phone">Şifre</Label>
                                        <Input.Password
                                            style={{ borderRadius: 10, lineHeight: "2.2", padding: "0px 10px" }}
                                            placeholder="Şifre"
                                            defaultValue={user?.password}
                                            onChange={e => setUser({ ...user, password: e.target.value })}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col className="pl-0" sm="12" md={{ size: 6, offset: 0 }}  >
                                    <Form.Item  {...tailLayout}>
                                        <Label for="phone">Şifre Tekrar</Label>
                                        <Input.Password
                                            style={{ borderRadius: 10, lineHeight: "2.2", padding: "0px 10px" }}
                                            placeholder="Şifre"
                                            defaultValue={user?.passwordCheck}
                                            onChange={e => setUser({ ...user, passwordCheck: e.target.value })}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>}


                            <Row className="mx-0" col="12">
                                <Col className="pl-0" sm="6" md={{ size: 6, offset: 0 }}>
                                    <Form.Item {...tailLayout}>
                                        <div className="d-flex flex-column">
                                            <Label for="phone">Profil Fotosu</Label>
                                            <div style={{ width: 102, height: 102, padding: 5, borderRadius: "10px", border: "1px dashed lightgray" }} >
                                                <img src={user?.avatar?.url} style={{ width: 92, height: 92, borderRadius: "10px" }} />
                                            </div>
                                        </div>
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Row className="mr-1">
                                <Col xs="12" sm="12" md={{ size: 3, offset: 9 }} >
                                    <Button type="primary" style={{ width: "100%", justifyContent: "center" }} icon={<AiOutlinePlus />} onClick={() => save()}> {id === "new" ? "Kaydet" : "Güncelle"}</Button>
                                </Col>
                            </Row>
                        </Form>
                    </CardBody>
                </Card>
            </Col>
        </Row >
    )
}

export default View