import React, { useEffect, useState } from 'react'
import {
    Card,
    CardHeader,
    CardTitle,
    CardBody,
    Row,
    Col,
    Label
} from "reactstrap"
import { Input, Form, Switch, Button, message } from 'antd'
import { AiOutlinePlus } from 'react-icons/ai'
import { useHistory, useParams } from "react-router-dom";
import PhoneInput from '../../components/PhoneInput';
import { useGetData, usePostData, usePutData, useUploadFile } from '../../hooks/serviceGetways';
import { CheckCircleOutlined } from '@ant-design/icons';

const tailLayout = {
    labelCol: { span: 0 },
    wrapperCol: { offset: 0, span: 24 },
};

let JobView = () => {

    let postData = usePostData()

    let download = async () => {

    }

    return (
        <Row>
            <Button >Rapor Indir</Button>
        </Row >
    )
}

export default JobView;
