import React, { useCallback, useEffect, useState } from "react"
import { message, Table, Pagination, Tag, Card, Button, Popconfirm, Divider } from "antd"
import { useDeleteData, useGetData } from "../../hooks/serviceGetways"
import { AimOutlined, EditOutlined } from '@ant-design/icons'
import moment from "moment";
import Viewer from "react-viewer";
import { Link } from 'react-router-dom';

let PoleList = () => {
    let [poles, setPoles] = useState([])
    let [showImages, setShowImages] = useState({ images: [], index: 0 });
    let [loadPage, setLoadPage] = useState(false)
    let [loading, setLoading] = useState(true)
    let [pagination, setPagination] = useState({ page: 0, pageSize: 100, total: 0 })
    let getData = useGetData()
    let deleteData = useDeleteData()

    let getPoles = useCallback(() => {
        getData("poles/list", { query: { ...pagination } }).then(({ result, resultMessage }) => {
            setPoles(result.rows)
            setPagination({ page: result.page, pageSize: result.pageSize, total: result.total })
            setLoadPage(true)
            setLoading(false)
        }).catch(err => {
            message.error(err.message)
        })
    }, [])

    useEffect(() => {
        getPoles()
    }, [])


    useEffect(() => {
        if (loadPage) {
            setLoading(true)
            getData("poles/list", { query: { ...pagination } }).then(({ result, resultMessage }) => {
                setPoles(result.rows)
            }).catch(err => {
                message.error(err.message)
            }).finally(() => {
                setLoading(false)
            })
        }
    }, [pagination])

    let deletePoles = (typeId) => {
        deleteData("poles/" + typeId, {}).then(({ result, resultMessage }) => {
            if (result)
                message.success("Direk başarılı bir şekilde silindi.")
            getPoles()
        }).catch(err => {
            message.error(err.message)
        })
    }

    let columns = [
        {
            title: 'Barkod', dataIndex: 'barcode', key: 'barcode', align: "center", render: (barcode, record) => <div>
                <div className="d-block">{barcode}</div>
                <div className="d-block mt-2"><Tag color="orange">Çevrilmiş Barkod: {record.convertBarcode}</Tag></div>
            </div>
        },
        {
            title: 'İşin Adı', dataIndex: 'jobName', key: 'jobName', align: "center", width: '15%', render: (jobName, record) => <div>
                <div className="d-block">{jobName}</div>
                <div className="mt-3">{record.lat && record.long ? <Tag color="green">Konum Var</Tag> : <Tag color="volcano">Konum Yok</Tag>}</div>
            </div>
        },
        {
            title: 'Bölge', dataIndex: 'district', key: 'district', align: "center", width: '10%', render: (district, record) => <div>
                <div className="d-block">{record.country}</div>
                <div className="d-block">{record.district}</div>
                <div className="d-block">{record.outskirts}</div>

            </div>
        },
        {
            title: 'Direk Türü', dataIndex: 'poleType', key: 'poleType', align: "center", render: (poleType, record) => <div className="d-flex flex-column gap-2">
                <Tag>{poleType?.toUpperCase()}</Tag>
                <Tag color={record?.poleGenre === "tasiyici" ? "yellow" : "orange"}>{record?.poleGenre?.toUpperCase()}</Tag>
            </div>
        },
        {
            title: 'Maintenance', width: '25%', dataIndex: 'maintenances', key: 'maintenances',
            align: "center",
            render: (maintenances) => (
                <ul>
                    {maintenances.filter(x => x.value).map(item => (
                        <li style={{ listStyle: 'none' }} key={item.key}>
                            {item.name}: {item.value ? 'Evet' : 'Hayır'} {item.countable ? `(${item.count})` : ''}
                        </li>
                    ))}
                </ul>
            )
        },
        {
            title: 'Images', align: 'center', dataIndex: 'images', key: 'images',
            width: '20%',
            render: (images) => <div className=" my-4 d-flex flex-wrap gap-3">
                {images.map((item, i) => <img className="pole-image" key={i} src={item?.thumb} width={50} onClick={() => setShowImages({ images, index: i })} alt="resimler" />)}
            </div>
        },
        {
            title: 'Eklenme Tarihi',
            dataIndex: 'createdAt',
            key: 'createdAt',
            width: '10%',
            align: "center",
            render: (createdAt) => moment(createdAt).format('DD.MM.YYYY HH:mm')
        },
        {
            title: 'Edit', dataIndex: "edit", key: "edit",
            render: (d, record) => (
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <Link to={`/job-poles/${record.jobId}?barcode=${record.convertBarcode}--${record.barcode}`}>
                        <Button icon={<EditOutlined />}>
                        </Button>
                    </Link>
                </div>
            )
        }
    ];

    return <>
        <Viewer
            downloadable={true}
            downloadInNewWindow={true}
            zoomSpeed={0.5}

            visible={!!showImages?.images?.length}
            onClose={() => { setShowImages({ images: [], index: 0 }); }}
            activeIndex={showImages?.index}
            images={showImages.images.map((img) => ({ src: img.url, alt: img._id, downloadUrl: img.url }))}
        />
        <div className="d-none d-md-block d-lg-block d-xl-block">
            <Table dataSource={poles} loading={loading} rowKey="_id" scroll={{ x: 780 }} pagination={false} columns={columns}>
            </Table>

        </div>
        <div>
            {poles.map(pole =>
                <Card key={pole._id} loading={loading} className="d-xxl-none d-xl-none d-lg-none d-md-none d-sm-block mb-3">
                    <div className="d-table justify-content-start align-items-center">
                        <div className="d-block mb-2">
                            <span className="fs-5">Barcode : {pole.barcode}</span>
                        </div>

                        <div className="d-block mb-2">
                            <span className="fs-6">{pole.jobName}</span>
                        </div>

                        <div className="d-block mb-2">
                            <span className=""> {pole.lat && pole.long ?
                                <Tag color="green"><AimOutlined className="me-1" />Konum Var</Tag> :
                                <Tag style={{ width: "fit-content" }} className="d-flex align-items-center justify-content-start" color="volcano"><AimOutlined /><span>Konum Yok</span></Tag>}</span>
                        </div>

                        <div className="d-block mb-2">
                            <span className="d-flex align-items-center">{<Tag>{pole.poleType.toUpperCase()}</Tag>}</span>
                        </div>

                        <div className="d-block mb-2">
                            <span>{<Tag>{pole.poleGenre.toUpperCase()}</Tag>}</span>
                        </div>

                        <div className="d-block my-2">
                            <ul className="m-0 my-2 p-0">
                                {pole.maintenances.filter(x => x.value).map(item => (
                                    <li style={{ listStyle: 'none' }} key={item.key}>
                                        {item.name}: {item.value ? 'Evet' : 'Hayır'} {item.countable ? `(${item.count})` : ''}
                                    </li>
                                ))}
                            </ul>
                        </div>

                        <div className="mt-2 d-flex justify-content-start align-items-center gap-2 overflow-x-auto">

                            {pole.images.map((item, i) => <img className="pole-image" key={i} src={item?.thumb} width={50} onClick={() => setShowImages({ images: pole.images, index: i })} alt="resimler" />)}
                        </div>

                    </div>
                </Card>)}
        </div>
        <div className="w-100 d-flex justify-content-end align-items-center mt-2">
            <Pagination showTotal={total => `Toplam : ${total} `}
                showSizeChanger
                pageSizeOptions={[50, 100, 500, 1000]}
                current={pagination.page}
                total={pagination.total}
                pageSize={pagination.pageSize}
                onChange={(p, ps) => setPagination({ ...pagination, page: p, pageSize: ps })}
            />
        </div>
    </>

}

export default PoleList