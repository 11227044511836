import React, { lazy, Suspense, useContext, useEffect } from 'react'
import { Route, useHistory } from 'react-router-dom'
import Cookies from 'js-cookie';
import axios from 'axios';
import Sidebar from '../components/Layout/Sidebar'
import '../App.less';
import '../assets/css/antd.css';
import '../assets/css/style.css';
import { ContextLayout } from '../utility/LayoutContext';

const DashboardLayoutRoute = ({ component: Component, ...rest }) => {
  let { token, setToken, setSettings, setUser, setLoading } = useContext(ContextLayout)
  let history = useHistory()

  useEffect(() => {
    setLoading(true)
    if (token) {
      try {
        axios.get(`https://pole.justalp.com/api/me`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }).then(({ data: { result } }) => {
          if (result) {
            setUser({ name: result.name, avatar: result.avatar, role: result.role })
            localStorage.setItem("user", JSON.stringify(result))
            setLoading(false)
          }
        }).catch(error => {
          localStorage.removeItem("user");
          Cookies.remove("token");
          setUser(null)
          setToken(null)
          setLoading(false)
          history.push("/login")
        })
      } catch (error) {
        localStorage.removeItem("user");
        Cookies.remove("token");
        setUser(null)
        setToken(null)
        setLoading(false)
        history.push("/login")
      }
    }
    else {
      localStorage.removeItem("user");
      Cookies.remove("token");
      setUser(null)
      setToken(null)
      setLoading(false)
      history.push("/login")
    }

    axios.get(`https://pole.justalp.com/api/settings`, {}).then(({ data: { result } }) => {
      setSettings(result)
    })
  }, [])

  return (
    <Route
      {...rest}
      render={(props) => (
        <Sidebar>
          <Component {...props} />
        </Sidebar>
      )}
    />
  );
};

export default DashboardLayoutRoute
