import React, { useCallback, useContext, useEffect, useState } from "react"
import { Button, message, Table, Pagination, Card, Tabs, Tooltip, Input, Switch, Empty, Popconfirm } from "antd"
import { useGetData, usePostData, usePutData } from "../../hooks/serviceGetways"
import { EditOutlined, CheckCircleOutlined, CloseCircleOutlined, ThunderboltFilled, Loading3QuartersOutlined, LoadingOutlined, ProjectOutlined } from '@ant-design/icons'
import { Link } from 'react-router-dom';
import { useHistory, useLocation } from "react-router-dom";
import DownloadReport from "../../components/DownloadReport"
import { ContextLayout } from "../../utility/LayoutContext";
import _ from "lodash";

const { Column } = Table;

const jobStatus = {
  "pending": "1. Seviye Yapılıyor",
  "completed": "1. Seviye Tamamlandı",
  "inactive": "Pasif",
  "second-pending": "2. Seviye Yapılıyor",
  "second-completed": "2. Seviye Tamamlandı",
}
let JobList = () => {
  const { user } = useContext(ContextLayout)
  let history = useHistory()
  let { search } = useLocation()
  const searchParams = new URLSearchParams(search);
  const tab = searchParams.get("tab");
  // const showType = searchParams.get("showType");
  let [activeTab, setActiveTab] = useState(tab || "1")
  let [completedJobs, setCompletedJobs] = useState([])
  let [secondCompletedJobs, setSecondCompletedJobs] = useState([])
  let [pendingJobs, setPendingJobs] = useState([])
  let [secondPendingJobs, setSecondPendingJobs] = useState([])
  let [inactiveJobs, setInactiveJobs] = useState([])
  let [pendingPagination, setPendingPagination] = useState({ page: 1, pageSize: 100, total: 0 })
  let [pendingSearch, setPendingSearch] = useState("")
  let [showTable, setShowTable] = useState(localStorage.getItem("showTable") || false)
  let [inactiveSearch, setInactiveSearch] = useState("")
  let [inactivePagination, setInactivePagination] = useState({ page: 1, pageSize: 100, total: 0 })
  let getData = useGetData()
  let putData = usePutData()
  let getJobs = useCallback(() => {
    getData("jobs", { query: { status: 'completed' } }).then(({ result, resultMessage }) => {
      setCompletedJobs(result.rows)
    }).catch(err => {
      message.error(err.message)
    })
    getData("jobs", { query: { status: 'pending' } }).then(({ result, resultMessage }) => {
      setPendingJobs(result.rows)
    }).catch(err => {
      message.error(err.message)
    })

    getData("jobs", { query: { status: 'inactive' } }).then(({ result, resultMessage }) => {
      setInactiveJobs(result.rows)
    }).catch(err => {
      message.error(err.message)
    })
    getData("jobs", { query: { status: 'second-pending' } }).then(({ result, resultMessage }) => {
      setSecondPendingJobs(result.rows)
    }).catch(err => {
      message.error(err.message)
    })

    getData("jobs", { query: { status: 'second-completed' } }).then(({ result, resultMessage }) => {
      setSecondCompletedJobs(result.rows)
    }).catch(err => {
      message.error(err.message)
    })

  }, [])

  useEffect(() => {
    getJobs()
  }, [])


  const secondPart = async (jobId) => {
    await putData("jobs/second/status", { _id: jobId })
      .then(res => {
        if (res.data)
          message.success("İş 2.seviye bakıma açıldı.")
        setCompletedJobs(prev => prev.map(p => ({ ...p, status: p._id === jobId ? "second-pending" : "completed" })))
      }).catch(() => {
        message.error("İş 2.seviye bakıma açılırken sorun oluştu.")
      })
  }


  return <>
    <Tabs type="card" defaultActiveKey="1" activeKey={activeTab} onChange={tab => { setActiveTab(tab); history.push(`?tab=${tab}`) }} tabBarExtraContent={
      <div className="d-flex align-items-center justify-content-center"><span className="fs-6 me-2">Tablo Görünümü:</span>
        <Switch checked={showTable} unCheckedChildren={"Hayır"} checkedChildren={"Evet"} onChange={(v) => {
          setShowTable(v);
          localStorage.setItem("showTable", v)
        }} />
      </div>
    }>

      <Tabs.TabPane tab="1. Seviye İşler" key="1">


        <Tabs>
          <Tabs.TabPane tab="1. Seviye Tamamlanan İşler" key="1.1">
            {/* <div className="d-flex align-items-center justify-content-end w-100 fs-6 mb-2">
              {user?.role === "admin" && < DownloadReport />}
            </div> */}

            {showTable ?
              <div className="d-block">
                <Table dataSource={completedJobs} rowKey="_id" scroll={{ x: 850 }} pagination={false}>
                  <Column dataIndex="cbsId" key="cbsId" title="CBS ID"></Column>
                  <Column dataIndex="name" key="name" title="İşin Adı" align="center" width="200px"></Column>
                  <Column dataIndex="city" key="city" title="İl" align="center"
                    filterSearch
                    filters={_.chain(completedJobs).map(x => ({ text: x.city, value: x.city })).uniqBy(x => x.value).value()}
                    onFilter={(value, record) => record.city.indexOf(value) === 0}
                  />
                  <Column dataIndex="country" key="country" title="Bölge" align="center"
                    filterSearch
                    filters={_.chain(completedJobs).map(x => ({ text: x.district, value: x.district })).uniqBy(x => x.value).value()}
                    onFilter={(value, record) => record.district.indexOf(value) === 0}
                    render={(x, record) =>
                      <div>{record.district}</div>
                    }
                  />
                  <Column dataIndex="outskirts" key="outskirts" title="Mahalle" align="center"></Column>
                  <Column dataIndex="distance" key="distance" title="Hat Uzunluğu" align="center"></Column>
                  <Column dataIndex="sebekeUnsur" key="sebekeUnsur" title="Şebeke Unsur" width="200px" align="center"></Column>
                  <Column dataIndex="type" key="type" title="Tip" align="center" width="200px"></Column>
                  <Column dataIndex="poleCount" key="poleCount" title="Direk Sayısı" align="center" width="200px"></Column>
                  <Column dataIndex="status" key="status" title="Durumu" align="center" render={(val) => jobStatus[val]}></Column>
                  <Column dataIndex="edit" key="edit" title="Aksiyon" align="center" width="150px" render={(text, record) => (
                    <div className="d-flex align-items-center justify-content-center gap-2">
                      <Link to={"/job-poles/" + record._id}>
                        <Tooltip title="Direk listesini görmek için tıklayın">
                          <Button icon={<ThunderboltFilled />} />
                        </Tooltip>
                      </Link>

                      {(user?.role === "admin" || user?.role === "outside") && <Link to={"/jobs/" + record._id}>
                        <Tooltip title="İşi düzenlemek için tıklayın">
                          <Button icon={<EditOutlined />} />
                        </Tooltip>
                      </Link>}

                      {(user?.role === "admin" || user?.role === "outside") &&
                        <Popconfirm
                          onConfirm={() => secondPart(record._id)} title="İşi 2.Seviye Bakıma Açmayı Onaylıyor musunuz?"
                          okText="Onayla" cancelText="Vazgeç">
                          <Tooltip title="İşi 2. seviyeye açmak için tıklayın">
                            <Button icon={<ProjectOutlined />} />
                          </Tooltip>
                        </Popconfirm>}
                    </div>
                  )}></Column>
                </Table>
              </div> :
              <div className="d-flex align-items-center justify-content-start flex-wrap gap-lg-4 overflow-auto" style={{ maxHeight: '75vh' }}>
                {completedJobs.length > 0 ? completedJobs.map(job =>
                  <div key={job._id} className="d-flex align-items-center justify-content-center flex-column">
                    <img width={120} className="cursor-pointer" src="./images/folder.png" alt="folder" onDoubleClick={() => history.push(`job-poles/${job._id}`)} />
                    <Tooltip className="w-100" title={
                      <div className="d-flex align-items-start justify-content-start flex-column  w-100">
                        <span>İşin Adı : {job.name}</span>
                        <span >Şebeke Unsur :{job.sebekeUnsur}</span>

                      </div>}>
                      <div style={{ cursor: 'pointer', maxWidth: 116, whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }}>{job.sebekeUnsur}</div></Tooltip>
                  </div>
                ) : <div className="w-100 d-flex align-items-center justify-content-center my-5"><Empty /></div>}
              </div>}
          </Tabs.TabPane>

          <Tabs.TabPane tab="1.Seviye Devam Eden İşler" key="1.2">
            <div className="d-flex w-100 align-items-center justify-content-start fs-6 font-weight-500">
              <Input.Search size="large" className="my-2" value={pendingSearch} onChange={(e) => setPendingSearch(e.target.value)} />
            </div>
            <div className="d-block">
              <Table dataSource={pendingSearch?.length < 1 ? pendingJobs :
                _.chain((pendingJobs || []))
                  .filter((item) => _.chain(item)
                    // .pickBy(_.isString)
                    .values()
                    .join('')
                    .toLower()
                    .includes(pendingSearch.toLocaleLowerCase())
                    .value())
                  .value()} rowKey="_id" scroll={{ x: 780 }} pagination={pendingPagination} onChange={(pagination) => setPendingPagination(pagination)}>
                <Column dataIndex="cbsId" key="cbsId" title="CBS ID"></Column>
                <Column dataIndex="name" key="name" title="İşin Adı" align="center" width="200px"></Column>
                <Column dataIndex="city" key="city" title="İl" align="center"
                  filterSearch
                  filters={_.chain(pendingJobs).map(x => ({ text: x.city, value: x.city })).uniqBy(x => x.value).value()}
                  onFilter={(value, record) => record.city.indexOf(value) === 0}
                />
                <Column dataIndex="country" key="country" title="Bölge" align="center"
                  filterSearch
                  filters={_.chain(pendingJobs).map(x => ({ text: x.district, value: x.district })).uniqBy(x => x.value).value()}
                    onFilter={(value, record) => record.district.indexOf(value) === 0}
                    render={(x, record) =>
                      <div>{record.district}</div>
                    }
                />
                <Column dataIndex="outskirts" key="outskirts" title="Mahalle" align="center"></Column>
                <Column dataIndex="distance" key="distance" title="Hat Uzunluğu" align="center"></Column>
                <Column dataIndex="sebekeUnsur" key="sebekeUnsur" title="Şebeke Unsur" width="200px" align="center"></Column>
                <Column dataIndex="type" key="type" title="Tip" align="center" width="200px"></Column>
                <Column dataIndex="poleCount" key="poleCount" title="Direk Sayısı" align="center" width="200px"></Column>
                <Column dataIndex="status" key="status" title="Durumu" align="center" render={(val) => jobStatus[val]}></Column>

                {(user?.role === "admin" || user?.role === "outside") && <Column dataIndex="edit" key="edit" title="Düzenle" align="center" width="150px" render={(text, record) => (
                  <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <Link to={"/jobs/" + record._id}>
                      <Button icon={<EditOutlined />}>
                      </Button>
                    </Link>
                  </div>
                )}></Column>}
              </Table>
            </div>
          </Tabs.TabPane>
        </Tabs>

      </Tabs.TabPane>

      <Tabs.TabPane tab="2. Seviye İşler" key="2">
        <Tabs>
          <Tabs.TabPane tab="2. Seviye Bakımda Olan İşler" key="2.1">
            {showTable ?
              <div className="d-block">
                <Table dataSource={secondPendingJobs} rowKey="_id" scroll={{ x: 1250 }} pagination={false}>
                  <Column dataIndex="cbsId" key="cbsId" title="CBS ID"></Column>
                  <Column dataIndex="name" key="name" title="İşin Adı" align="center" width="200px"></Column>
                  <Column dataIndex="city" key="city" title="İl" align="center"
                    filterSearch
                    filters={_.chain(secondPendingJobs).map(x => ({ text: x.city, value: x.city })).uniqBy(x => x.value).value()}
                    onFilter={(value, record) => record.city.indexOf(value) === 0}
                  ></Column>
                  <Column dataIndex="country"
                    key="country"
                    title="Bölge"
                    align="center"
                    filterSearch
                    filters={_.chain(secondPendingJobs).map(x => ({ text: x.district, value: x.district })).uniqBy(x => x.value).value()}
                    onFilter={(value, record) => record.district.indexOf(value) === 0}
                    render={(x, record) =>
                      <div>{record.district}</div>
                    }
                  ></Column>
                  <Column dataIndex="district" key="district" title="İlçe" align="center"></Column>
                  <Column dataIndex="outskirts" key="outskirts" title="Mahalle" align="center"></Column>
                  <Column dataIndex="distance" key="distance" title="Hat Uzunluğu" align="center"></Column>
                  <Column dataIndex="sebekeUnsur" key="sebekeUnsur" title="Şebeke Unsur" width="200px" align="center"></Column>
                  <Column dataIndex="type" key="type" title="Tip" align="center" width="200px"></Column>
                  <Column dataIndex="poleCount" key="poleCount" title="Direk Sayısı" align="center"></Column>
                  <Column dataIndex="status" key="status" title="Durumu" align="center" render={(val) => jobStatus[val]}></Column>
                  <Column dataIndex="edit" key="edit" title="Aksiyon" align="center" width="75px" render={(text, record) => (
                    <div className="d-flex align-items-center justify-content-center gap-2">
                      <Link to={"/job-poles/" + record._id}>
                        <Tooltip title="Direk listesini görmek için tıklayın">
                          <Button icon={<ThunderboltFilled />} />
                        </Tooltip>
                      </Link>

                      {(user?.role === "admin" || user?.role === "outside") && <Link to={"/jobs/" + record._id}>
                        <Tooltip title="İşi düzenlemek için tıklayın">
                          <Button icon={<EditOutlined />} />
                        </Tooltip>
                      </Link>}
                    </div>
                  )}></Column>
                </Table>
              </div> :
              <div className="d-flex align-items-center justify-content-start flex-wrap gap-lg-4 overflow-auto" style={{ maxHeight: '75vh' }}>
                {secondPendingJobs.length > 0 ? secondPendingJobs.map(job =>
                  <div key={job._id} className="d-flex align-items-center justify-content-center flex-column">
                    <img width={120} className="cursor-pointer" src="./images/folder.png" alt="folder" onDoubleClick={() => history.push(`job-poles/${job._id}`)} />
                    <Tooltip className="w-100" title={
                      <div className="d-flex align-items-start justify-content-start flex-column  w-100">
                        <span>İşin Adı : {job.name}</span>
                        <span >Şebeke Unsur :{job.sebekeUnsur}</span>
                      </div>}>
                      <div style={{ cursor: 'pointer', maxWidth: 116, whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }}>{job.sebekeUnsur}</div></Tooltip>
                  </div>
                ) : <div className="w-100 d-flex align-items-center justify-content-center my-5"><Empty /></div>}
              </div>}
          </Tabs.TabPane>

          <Tabs.TabPane tab="2. Seviye Tamamlanan İşler" key="2.2">
            {showTable ?
              <div className="d-block">
                <Table dataSource={secondCompletedJobs} rowKey="_id" scroll={{ x: 850 }} pagination={false}>
                  <Column dataIndex="cbsId" key="cbsId" title="CBS ID"></Column>
                  <Column dataIndex="name" key="name" title="İşin Adı" align="center" width="200px"></Column>
                  <Column dataIndex="city" key="city" title="İl" align="center"
                    filterSearch
                    filters={_.chain(secondCompletedJobs).map(x => ({ text: x.city, value: x.city })).uniqBy(x => x.value).value()}
                    onFilter={(value, record) => record.city.indexOf(value) === 0}
                  />
                  <Column dataIndex="country" key="country" title="Bölge" align="center"
                    filterSearch
                    filters={_.chain(secondCompletedJobs).map(x => ({ text: x.district, value: x.district })).uniqBy(x => x.value).value()}
                    onFilter={(value, record) => record.district.indexOf(value) === 0}
                    render={(x, record) =>
                      <div>{record.district}</div>
                    }
                  />
                  <Column dataIndex="outskirts" key="outskirts" title="Mahalle" align="center"></Column>
                  <Column dataIndex="distance" key="distance" title="Hat Uzunluğu" align="center"></Column>
                  <Column dataIndex="sebekeUnsur" key="sebekeUnsur" title="Şebeke Unsur" width="200px" align="center"></Column>
                  <Column dataIndex="type" key="type" title="Tip" align="center" width="200px"></Column>
                  <Column dataIndex="poleCount" key="poleCount" title="Direk Sayısı" align="center" width="200px"></Column>
                  <Column dataIndex="status" key="status" title="Durumu" align="center" render={(val) => jobStatus[val]}></Column>
                  <Column dataIndex="edit" key="edit" title="Aksiyon" align="center" width="150px" render={(text, record) => (
                    <div className="d-flex align-items-center justify-content-center gap-2">
                      <Link to={"/job-poles/" + record._id}>
                        <Tooltip title="Direk listesini görmek için tıklayın">
                          <Button icon={<ThunderboltFilled />} />
                        </Tooltip>
                      </Link>

                      {(user?.role === "admin" || user?.role === "outside") && <Link to={"/jobs/" + record._id}>
                        <Tooltip title="İşi düzenlemek için tıklayın">
                          <Button icon={<EditOutlined />} />
                        </Tooltip>
                      </Link>}
                    </div>
                  )}></Column>
                </Table>
              </div> :
              <div className="d-flex align-items-center justify-content-start flex-wrap gap-lg-4 overflow-auto" style={{ maxHeight: '75vh' }}>
                {secondCompletedJobs.length > 0 ? secondCompletedJobs.map(job =>
                  <div key={job._id} className="d-flex align-items-center justify-content-center flex-column">
                    <img width={120} className="cursor-pointer" src="./images/folder.png" alt="folder" onDoubleClick={() => history.push(`job-poles/${job._id}`)} />
                    <Tooltip className="w-100" title={
                      <div className="d-flex align-items-start justify-content-start flex-column w-100">
                        <span>İşin Adı : {job.name}</span>
                        <span >Şebeke Unsur :{job.sebekeUnsur}</span>
                      </div>}>
                      <div style={{ cursor: 'pointer', maxWidth: 116, whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }}>{job.sebekeUnsur}</div></Tooltip>
                  </div>
                ) : <div className="w-100 d-flex align-items-center justify-content-center my-5"><Empty /></div>}
              </div>}
          </Tabs.TabPane>
        </Tabs>
      </Tabs.TabPane>


      <Tabs.TabPane tab={<div className="d-flex text-danger">Pasif İşler</div>} key="3">
        <div className="d-flex w-100 align-items-center justify-content-start fs-6 font-weight-500">
          <Input.Search size="large" className="my-2" value={inactiveSearch} onChange={(e) => setInactiveSearch(e.target.value)} />
        </div>
        <div className="d-block">
          <Table dataSource={inactiveSearch?.length < 1 ? inactiveJobs :
            _.chain((inactiveJobs || []))
              .filter((item) => _.chain(item)
                // .pickBy(_.isString)
                .values()
                .join('')
                .toLower()
                .includes(inactiveSearch.toLocaleLowerCase())
                .value())
              .value()} rowKey="_id" scroll={{ x: 780 }} pagination={inactivePagination} onChange={(pagination) => setInactivePagination(pagination)}>
            <Column dataIndex="cbsId" key="cbsId" title="CBS ID"></Column>
            <Column dataIndex="name" key="name" title="İşin Adı" align="center" width="200px"></Column>
            <Column dataIndex="city" key="city" title="İl" align="center"
              filterSearch
              filters={_.chain(inactiveJobs).map(x => ({ text: x.city, value: x.city })).uniqBy(x => x.value).value()}
              onFilter={(value, record) => record.city.indexOf(value) === 0}
            />
            <Column dataIndex="country" key="country" title="Bölge" align="center"
              filterSearch
              filters={_.chain(inactiveJobs).map(x => ({ text: x.district, value: x.district })).uniqBy(x => x.value).value()}
              onFilter={(value, record) => record.district.indexOf(value) === 0}
              render={(x, record) =>
                <div>{record.district}</div>
              }
            />
            <Column dataIndex="outskirts" key="outskirts" title="Mahalle" align="center"></Column>
            <Column dataIndex="distance" key="distance" title="Hat Uzunluğu" align="center"></Column>
            <Column dataIndex="sebekeUnsur" key="sebekeUnsur" title="Şebeke Unsur" width="200px" align="center"></Column>
            <Column dataIndex="type" key="type" title="Type" align="center" width="200px"></Column>
            <Column dataIndex="active" key="active" title="Durumu" align="center" render={(val, record) => val ? <CheckCircleOutlined style={{ fontSize: 18 }} /> : <CloseCircleOutlined style={{ fontSize: 18 }} />}></Column>
            {(user?.role === "admin" || user?.role === "outside") && <Column dataIndex="edit" key="edit" title="Düzenle" align="center" width="150px" render={(text, record) => (
              <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                <Link to={"/jobs/" + record._id}>
                  <Button icon={<EditOutlined />}>
                  </Button>
                </Link>
              </div>
            )}></Column>}
          </Table>
        </div>
      </Tabs.TabPane>
    </Tabs>
    <div className="w-100 d-flex justify-content-end align-items-center mt-2 pb-3">

    </div>
  </>

}

export default JobList
