import React, { useEffect, useState } from 'react'
import { Upload } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

function getBase64(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });
}

let Index = (props) => {
    const { record, setRecord, name } = props
    let [fileList, setFileList] = useState([])

    let handleChange = ({ fileList }) => {
        if (!fileList || fileList.length < 1)
            setFileList([])
        else if (fileList) {
            setRecord({ ...record, [name]: fileList[0].originFileObj })
            setFileList(fileList)
        }
    }

    useEffect(() => {
        if (record[name] && record[name].url !== "")
            setFileList([{
                _id: record[name]._id,
                uid: record[name]._id,
                name: "Dosya",
                status: 'done',
                url: record[name].url
            }])
    }, [])


    const uploadButton = (
        <div>
            <PlusOutlined />
            <div style={{ marginTop: 8 }}>Yükle</div>
        </div>
    );
    const dummyRequest = ({ file, onSuccess }) => {
        setTimeout(() => {
            onSuccess("ok");
        }, 0);
    };

    return (
        <>
            <Upload
                multiple={false}
                customRequest={dummyRequest}
                listType="picture-card"
                fileList={fileList}
                onChange={handleChange}
            >
                {fileList.length >= 1 ? null : uploadButton}
            </Upload>
        </>
    );
}

export default Index