
import React, { useState } from 'react'
import { Button, message, Tag, Tooltip } from 'antd'
import { usePostData, files, zipFiles } from '../hooks/serviceGetways';
import _ from 'lodash';
import axios from 'axios';
import { WarningFilled } from '@ant-design/icons';

const tailLayout = {
  labelCol: { span: 0 },
  wrapperCol: { offset: 0, span: 24 },
};

let DownloadReport = (props) => {
  let [loading, setLoading] = useState(false)
  let postData = usePostData()

  let download = async () => {
    setLoading(true)
    await postData("jobs/generateReport", { jobId: props?.jobId ? props.jobId : undefined });
    const link = document.createElement('a');
    link.href = `${files}/${_.chain(props?.sebekeUnsur).deburr().replace(/ /g, '').snakeCase().value()}.xlsx`;
    link.setAttribute("download", "Rapor.xlsx");

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    setLoading(false)
  }

  const timeout = (delay) => {
    return new Promise(res => setTimeout(res, delay));
  }




  let downloadPhotos = async () => {
    setLoading(true)
    let uri = zipFiles + _.snakeCase(`${_.deburr(props.jobName)}_${_.deburr(props.sebekeUnsur)}`) + ".zip";
    try {


      let http = new XMLHttpRequest();
      http.open('HEAD', uri, false);
      http.send();

      if (http.status !== 200)
        throw new Error("olustur");
      const link = document.createElement('a');
      link.href = uri;
      link.setAttribute("download", "Photos.zip");

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
    catch (error) {

      await postData("poles/photosBunny", { _id: props?.jobId ? props.jobId : undefined }).catch((err) => {
        message.error(err.message)
      });
      let http = new XMLHttpRequest();
      http.open('HEAD', uri, false);
      http.send();

      if (http.status === 200) {
        await timeout(10000);
        const link = document.createElement('a');
        link.href = uri;
        link.setAttribute("download", "Photos.zip");

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
      else {
        window.location.reload();
      }
    }




    setLoading(false)
  }

  return (<>
    <div className='d-flex align-items-center justify-content-center gap-3'>
      <Button loading={loading} onClick={download} >Tamamlanan İş Rapor İndir</Button>
      {props?.jobId && <Button disabled={props.zipStatus === "progress"} loading={loading} onClick={downloadPhotos} >Fotoğrafları İndir</Button>}
      {props.zipStatus === "progress" && <Tag color='volcano'>Zip Oluşturuluyor.</Tag>}
      {props.zipStatus === "completed" && <Tag color='green'>Zip Oluşturuldu.</Tag>}
    </div>
  </>
  )
}

export default DownloadReport;
